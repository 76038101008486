import { gql } from '@apollo/client';



export const CHECK_CATALOG_NAME_AVAILABILITY = gql`
    query CheckCatalogNameAvailability($catalogName: String!) {
        checkCatalogNameAvailability(catalogName: $catalogName)
    }
`;


// export const GET_CATALOGS = gql`
//     query GetCatalogs {
//         catalogs {
//         id
//         name
//         description
//         logo
//         }
//     }
// `;

export const GET_CATALOG = gql`
    query GetCatalog($id: ID!) {
        catalog(id: $id) {
        id
        name
        description
        logo
        phone
        socialMedia {
            facebook
            twitter
            instagram
            tiktok
            email
            website
            telegram
        }
        theme
        viewMode
        lastUpdated
        onboardingStatus
        onboardingStep
        }
  }
`;

export const GET_USER_DATA = gql`
query GetUserData {
  User {
    id
    name
    email
    # other fields...
  }
}
`;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      name
      email
      isGoogleAccount
      twoFactorEnabled
      isVerified
      selectedCatalog {
        id
        catalogName
        name
        logo
        onboardingStatus
        onboardingStep        
      }
    }
  }
`;

export const GET_USER_CATALOGS = gql`
  query GetUserCatalogs($userId: ID!) {
    getUserWithCatalogs(userId: $userId) {
      name
      catalogs {
        id
        catalogName
        name
        logo
        description
      }
    }
  }
`;

export const CATALOG_PERFORMANCE = gql`
  query CatalogPerformance($catalogId: ID!, $days: Int!) {
    catalogPerformance(catalogId: $catalogId, days: $days) {
      totalCategories
      totalItems
      totalItemClicks
      performancePercentageChange
    }
  }
`;

export const TOP_ITEMS_BY_CLICKS = gql`
  query TopItemsByClicks($catalogId: ID!, $limit: Int!, $previousDays: Int!) {
    topItemsByClicks(catalogId: $catalogId, limit: $limit, previousDays: $previousDays) {
      id
      name
      clickCount
      percentageDifference
    }
  }
`;

export const GET_CATALOG_ITEMS_AND_CATEGORIES = gql`
  query GetCatalogItemsAndCategories($catalogId: ID!) {
    catalogItemsAndCategories(catalogId: $catalogId) {
      categories {
        id
        name
        order
        isVisible
        items {
          id
          name
          description
          category
          priceVariants {
            variant
            price
          }
          image
          clickCount
          order
          isVisible
        }
      }
      uncategorizedItems {
        id
        name
        description
        priceVariants {
          variant
          price
        }
        image
        clickCount
        order
        isVisible
      }
    }
  }
`;

export const GET_CATALOG_STATISTICS = gql`
  query GetCatalogStatistics($catalogId: ID!, $days: Int!) {
  getCatalogStatistics(catalogId: $catalogId, days: $days) {
    totalCategories
    totalItems
    totalItemClicks
    performancePercentageChange
    dailyVisits {
      date
      visits
    }
    topItems {
      id
      name
      clickCount
    }
  }
  }
`;


export const GET_CATALOG_BY_NAME = gql`
  query GetCatalogByName($catalogName: String!) {
    getCatalogByName(catalogName: $catalogName) {
      catalog {
        id
        name
        description
        logo
        phone
        socialMedia {
          facebook
          twitter
          instagram
          tiktok
          email
          website
          telegram
        }
        theme
        viewMode
      }
      categoriesWithItems {
        id
        name
        order
        items {
          id
          name
          description
          priceVariants {
            variant
            price
          }
          image
          isVisible
          order
        }
      }
      uncategorizedItems {
        id
        name
        description
        priceVariants {
          variant
          price
        }
        image
        isVisible
        order
      }
    }
  }
`;

export const COMBINED_OVERVIEW_DATA = gql`
query CombinedOverviewData($catalogId: ID!, $days: Int!, $limit: Int!) {
  overviewData(catalogId: $catalogId, days: $days, limit: $limit) {
    performance {
      totalCategories
      totalItems
      weeklyCatalogViews
      performancePercentageChange
    }
    topItems {
      id
      name
      description
      priceVariants {
        variant
        price
      }
      clickCount
      percentageDifference
    }
  }
}
`;

export const GET_VENDOR_FAQS = gql`
     query GetVendorFAQs {
       vendorFAQs {
         id
         question
         answer
       }
     }
   `;