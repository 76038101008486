import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CATALOG_ITEMS_AND_CATEGORIES } from '../graphql/queries';
import { CREATE_ITEM, CREATE_CATEGORY, UPDATE_ITEM, DELETE_ITEM, UPDATE_CATEGORY, DELETE_CATEGORY, REORDER_CATALOG } from '../graphql/mutations';
import { useAuth } from '../utils/authContext';
import Layout from '../components/Layout';
import { Search, Plus, Filter, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, Edit, Trash } from 'lucide-react';
import AddItemPopup from '../components/AddItemPopup';
import AddCategoryPopup from '../components/AddCategoryPopup';
import SuccessMessagePopup from '../components/SuccessMessagePopup';
import ItemGrid from '../components/ItemGrid';
import ItemDetailPopup from '../components/ItemDetailPopup';
import EditCategoryPopup from '../components/EditCategoryPopup';
import DeleteConfirmationPopup from '../components/DeleteConfirmationPopup';
import Toggle from '../components/Toggle';
import ReturnToOnboardingButton from '../components/ReturnToOnboardingButton';
import LoadingSpinner from '../components/LoadingSpinner';
import CatalogManagement from '../components/CatalogManagement';
import { useCallback } from 'react';
import ErrorBoundary from '../components/ErrorBoundary';
import EditItemPopup from '../components/EditItemPopup';


const Catalog = () => {
    const { user, catalogs, refetchUserData } = useAuth();
    const catalogId = user?.selectedCatalog?.id || catalogs[0]?.id;

    const { loading, error, data, refetch } = useQuery(GET_CATALOG_ITEMS_AND_CATEGORIES, {
        variables: { catalogId },
        skip: !catalogId,
    });




    const [reorderCatalog] = useMutation(REORDER_CATALOG);
    const [isManagementMode, setIsManagementMode] = useState(false);
    const [createItem] = useMutation(CREATE_ITEM);
    const [createCategory] = useMutation(CREATE_CATEGORY);
    const [updateItem] = useMutation(UPDATE_ITEM);
    const [deleteItem] = useMutation(DELETE_ITEM);
    const [updateCategory] = useMutation(UPDATE_CATEGORY);

    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState('all');
    const [isAddItemPopupOpen, setIsAddItemPopupOpen] = useState(false);
    const [isAddCategoryPopupOpen, setIsAddCategoryPopupOpen] = useState(false);
    const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);
    const [successMessageContent, setSuccessMessageContent] = useState({ title: '', message: '' });
    const [quickAddCategory, setQuickAddCategory] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isItemDetailPopupOpen, setIsItemDetailPopupOpen] = useState(false);


    const [deleteCategory] = useMutation(DELETE_CATEGORY);
    const [isEditCategoryPopupOpen, setIsEditCategoryPopupOpen] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

    const [itemToDelete, setItemToDelete] = useState(null);
    const [isDeleteItemConfirmationOpen, setIsDeleteItemConfirmationOpen] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [isEditItemPopupOpen, setIsEditItemPopupOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);


    useEffect(() => {
        refetchUserData(); // Refetch user data when component mounts
    }, [refetchUserData]);

    useEffect(() => {
        if (data && data.catalogItemsAndCategories) {
            const categoriesWithItemCount = data.catalogItemsAndCategories.categories.map(cat => ({
                ...cat,
                isExpanded: true,
                items: cat.items || [],
                itemCount: cat.items ? cat.items.length : 0
            }));

            setCategories(categoriesWithItemCount);

            setItems([
                ...data.catalogItemsAndCategories.categories.flatMap(cat => cat.items || []),
                ...data.catalogItemsAndCategories.uncategorizedItems
            ]);
        }
    }, [data]);


    const handleOpenEditItem = (item) => {
        // console.log("Opening edit item popup for:", item);
        setItemToEdit(item);
        setIsEditItemPopupOpen(true);
        setIsItemDetailPopupOpen(false);
    };

    const handleEditItem = (updatedItem, error) => {
        if (error) {
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to update item: ${error.message}`
            });
            setIsSuccessMessageOpen(true);
        } else if (updatedItem) {
            setItems(prevItems => prevItems.map(item =>
                item.id === updatedItem.id ? updatedItem : item
            ));
            setSuccessMessageContent({
                title: "Item Updated",
                message: "The item has been updated successfully!"
            });
            setIsSuccessMessageOpen(true);
            refetch();
        }
        setIsEditItemPopupOpen(false);
    };

    const handleReorderSuccess = useCallback((updatedOrder) => {
        setCategories(updatedOrder.categoriesWithItems);
        setItems([
            ...updatedOrder.categoriesWithItems.flatMap(cat => cat.items || []),
            ...(updatedOrder.itemsWithoutCategory || [])
        ]);
        setSuccessMessageContent({
            title: "Catalog Updated",
            message: "The catalog order has been updated successfully!"
        });
        setIsSuccessMessageOpen(true);

        // Refetch to ensure server and client states are in sync
        refetch();
    }, [refetch]);

    const handleError = useCallback((error) => {
        console.error('An error occurred:', error);
        setSuccessMessageContent({
            title: "Error",
            message: "An error occurred while reordering. The changes may not have been saved. Please try again or reload the page."
        });
        setIsSuccessMessageOpen(true);
    }, []);

    const categoryRefs = useRef({});

    const scrollToCategory = (categoryId) => {
        categoryRefs.current[categoryId]?.scrollIntoView({ behavior: 'smooth' });
    };

    const getCategoryItemCount = (categoryName) => {
        return items.filter(item => item.category === categoryName).length;
    };

    const toggleCategoryExpansion = (categoryId) => {
        setCategories(categories.map(cat =>
            cat.id === categoryId ? { ...cat, isExpanded: !cat.isExpanded } : cat
        ));
    };

    const handleAddItem = async (newItem, error) => {
        if (error) {
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to add item: ${error.message}`
            });
        } else {
            setItems(prevItems => [...prevItems, newItem]);

            // Update category item count
            if (newItem.category) {
                setCategories(prevCategories =>
                    prevCategories.map(cat =>
                        cat.id === newItem.category
                            ? { ...cat, itemCount: (cat.itemCount || 0) + 1 }
                            : cat
                    )
                );
            }

            setSuccessMessageContent({
                title: "Item Added",
                message: "The item has been added successfully!"
            });
        }
        setIsAddItemPopupOpen(false);
        setIsSuccessMessageOpen(true);
        refetch();
    };

    const handleAddCategory = async (newCategoryName) => {
        try {
            const { data } = await createCategory({
                variables: {
                    name: newCategoryName,
                    catalog: catalogId,
                    isVisible: true,
                },
            });

            if (data && data.createCategory) {
                setCategories(prevCategories => [
                    ...prevCategories,
                    { ...data.createCategory, isExpanded: true, items: [] }
                ]);
                setSuccessMessageContent({
                    title: "Category Added",
                    message: "The category has been added successfully!"
                });
            } else {
                throw new Error("Failed to create category");
            }
        } catch (error) {
            console.error("Error adding category:", error);
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to add category: ${error.message}`
            });
        } finally {
            setIsAddCategoryPopupOpen(false);
            setIsSuccessMessageOpen(true);
            refetch();
        }
    };


    const handleSuccessMessageClose = () => {
        setIsSuccessMessageOpen(false);
    };


    const handleEditCategory = (category) => {
        setSelectedCategory(category);
        setIsEditCategoryPopupOpen(true);
    };

    const handleEditCategorySubmit = (updatedCategory, error) => {
        if (error) {
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to update category: ${error.message}`
            });
        } else {
            setCategories(prevCategories =>
                prevCategories.map(cat =>
                    cat.id === updatedCategory.id ? { ...cat, name: updatedCategory.name } : cat
                )
            );
            setSuccessMessageContent({
                title: "Category Updated",
                message: "The category has been updated successfully!"
            });
        }
        setIsEditCategoryPopupOpen(false);
        setIsSuccessMessageOpen(true);
    };

    const handleDeleteItemClick = (item) => {
        setItemToDelete(item);
        setIsDeleteItemConfirmationOpen(true);
        setIsItemDetailPopupOpen(false); // Close the item detail popup
    };

    const handleDeleteItemConfirm = async () => {
        if (!itemToDelete) return;

        try {
            const { data } = await deleteItem({
                variables: { id: itemToDelete },
                update: (cache) => {
                    cache.modify({
                        fields: {
                            items(existingItems = [], { readField }) {
                                return existingItems.filter(
                                    itemRef => itemToDelete.id !== readField('id', itemRef)
                                );
                            }
                        }
                    });
                }
            });

            if (data.deleteItem === 'Item removed') {
                // Update local state
                setItems(prevItems => prevItems.filter(item => item.id !== itemToDelete.id));

                // Update category item count
                if (itemToDelete.category) {
                    setCategories(prevCategories =>
                        prevCategories.map(cat =>
                            cat.id === itemToDelete.category
                                ? { ...cat, itemCount: Math.max((cat.itemCount || 0) - 1, 0) }
                                : cat
                        )
                    );
                }

                setSuccessMessageContent({
                    title: "Item Deleted",
                    message: "The item has been deleted successfully!"
                });
            } else {
                throw new Error('Failed to delete item');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to delete item: ${error.message}`
            });
        } finally {
            setIsDeleteItemConfirmationOpen(false);
            setItemToDelete(null);
            setIsSuccessMessageOpen(true);
            refetchCatalog();
        }
    };


    const handleToggleItemVisibility = async (itemId) => {
        const itemToUpdate = items.find(item => item.id === itemId);
        if (itemToUpdate) {
            try {
                const { data } = await updateItem({
                    variables: {
                        id: itemId,
                        isVisible: !itemToUpdate.isVisible
                    }
                });

                // Update the local state to reflect the change
                setItems(prevItems => prevItems.map(item =>
                    item.id === itemId ? { ...item, isVisible: data.updateItem.isVisible } : item
                ));

                // Update the categories state if the item is in a category
                setCategories(prevCategories => prevCategories.map(category => ({
                    ...category,
                    items: category.items ? category.items.map(item =>
                        item.id === itemId ? { ...item, isVisible: data.updateItem.isVisible } : item
                    ) : []
                })));

                setSuccessMessageContent({
                    title: "Item Visibility Updated",
                    message: `The item is now ${data.updateItem.isVisible ? 'visible' : 'hidden'}.`
                });
                setIsSuccessMessageOpen(true);
            } catch (error) {
                console.error("Error toggling item visibility:", error);
                setSuccessMessageContent({
                    title: "Error",
                    message: `Failed to update item visibility: ${error.message}`
                });
                setIsSuccessMessageOpen(true);
            }
        }
    };


    const handleToggleCategoryVisibility = async (categoryId) => {
        const categoryToUpdate = categories.find(category => category.id === categoryId);
        if (categoryToUpdate) {
            try {
                const { data } = await updateCategory({
                    variables: {
                        id: categoryId,
                        isVisible: !categoryToUpdate.isVisible
                    }
                });
                setCategories(categories.map(category =>
                    category.id === categoryId ? { ...category, isVisible: data.updateCategory.isVisible } : category
                ));
                setSuccessMessageContent({
                    title: "Category Visibility Updated",
                    message: `The category is now ${data.updateCategory.isVisible ? 'visible' : 'hidden'}.`
                });
                setIsSuccessMessageOpen(true);
            } catch (error) {
                console.error("Error toggling category visibility:", error);
                setSuccessMessageContent({
                    title: "Error",
                    message: `Failed to update category visibility: ${error.message}`
                });
                setIsSuccessMessageOpen(true);
            }
        }
    };

    const handleDeleteCategoryClick = (category) => {
        setSelectedCategory(category);
        setIsDeleteConfirmationOpen(true);
    };

    const handleDeleteCategoryConfirm = async () => {
        try {
            await deleteCategory({
                variables: { id: selectedCategory.id },
            });
            setCategories(prevCategories => prevCategories.filter(cat => cat.id !== selectedCategory.id));
            setItems(prevItems =>
                prevItems.map(item =>
                    item.category === selectedCategory.id ? { ...item, category: null } : item
                )
            );
            setSuccessMessageContent({
                title: "Category Deleted",
                message: "The category has been deleted successfully!"
            });
            setIsSuccessMessageOpen(true);
        } catch (error) {
            console.error("Error deleting category:", error);
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to delete category: ${error.message}`
            });
            setIsSuccessMessageOpen(true);
        }
        setIsDeleteConfirmationOpen(false);
    };




    const addQuickItem = (categoryId, categoryName) => {
        setQuickAddCategory({ id: categoryId, name: categoryName });
        setIsAddItemPopupOpen(true);
    };

    const filteredItems = items.filter(item =>
        filter === 'all' || (filter === 'shown' && item.isVisible) || (filter === 'hidden' && !item.isVisible)
    );

    const categorizedItems = categories.map(category => ({
        ...category,
        items: filteredItems.filter(item => item.category === category.name)
    }));

    const uncategorizedItems = filteredItems.filter(item => item.category === null);

    const categoriesRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const scroll = (scrollOffset) => {
        if (categoriesRef.current) {
            categoriesRef.current.scrollLeft += scrollOffset;
        }
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - categoriesRef.current.offsetLeft);
        setScrollLeft(categoriesRef.current.scrollLeft);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // const handleToggleItemVisibility = (itemId) => {
    //     setItems(items.map(item =>
    //         item.id === itemId ? { ...item, isVisible: !item.isVisible } : item
    //     ));
    // };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - categoriesRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust scrolling speed
        categoriesRef.current.scrollLeft = scrollLeft - walk;
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isDragging, startX, scrollLeft]);

    const handleTouchStart = (e) => {
        setIsDragging(true);
        setStartX(e.touches[0].pageX - categoriesRef.current.offsetLeft);
        setScrollLeft(categoriesRef.current.scrollLeft);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.touches[0].pageX - categoriesRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        categoriesRef.current.scrollLeft = scrollLeft - walk;
    };


    const handleOpenItemDetail = (item) => {
        setSelectedItem(item);
        setIsItemDetailPopupOpen(true);
    };

    const handleCloseItemDetail = () => {
        setSelectedItem(null);
        setIsItemDetailPopupOpen(false);
    };

    const refetchCatalog = useCallback(async () => {
        try {
            const { data } = await refetch();
            if (data && data.catalogItemsAndCategories) {
                const categoriesWithItemCount = data.catalogItemsAndCategories.categories.map(cat => ({
                    ...cat,
                    isExpanded: true,
                    items: cat.items || [],
                    itemCount: cat.items ? cat.items.length : 0
                }));

                setCategories(categoriesWithItemCount);
                setItems([
                    ...data.catalogItemsAndCategories.categories.flatMap(cat => cat.items || []),
                    ...data.catalogItemsAndCategories.uncategorizedItems
                ]);
            }
        } catch (error) {
            console.error('Error refetching catalog data:', error);
            handleError(error);
        }
    }, [refetch, handleError]);


    if (loading || !user || !catalogId) return <LoadingSpinner />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Layout
            title="Catalog"
            description="Manage your catalog's items and categories"
        >

            <div className='min-h-screen'>
                {/* <div className="p-1 sm:p-1 lg:p-8 bg-gray-100]"> */}
                {/* Top bar */}
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
                    <div className="relative w-full sm:w-auto max-w-md">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="w-full sm:w-auto pl-10 pr-4 py-2 rounded-xl border border-gray-300 bg-transparent focus:outline-none focus:ring-2 focus:ring-[#191970]"
                        />
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                    <div className="flex space-x-2 sm:space-x-4 w-full sm:w-auto text-xs md:text-sm">
                        <button onClick={() => setIsAddItemPopupOpen(true)} className="flex-1 sm:flex-none bg-white text-#191970 px-3 py-2 rounded-lg flex items-center justify-center border-[#191970]">
                            <Plus className="w-4 h-4 mr-2" /> New Item
                        </button>
                        <button onClick={() => setIsAddCategoryPopupOpen(true)} className="flex-1 sm:flex-none bg-[#191970] text-white px-3 py-2 rounded-lg flex items-center justify-center">
                            <Plus className="w-4 h-4 mr-2" /> New Category
                        </button>
                        {/* <button
                            onClick={() => setIsManagementMode(!isManagementMode)}
                            className={`px-3 py-2 rounded-lg flex items-center justify-center ${isManagementMode ? 'bg-gray-200 text-gray-800' : 'bg-blue-500 text-white'
                                }`}
                        >
                            {isManagementMode ? 'Exit Management' : 'Manage Catalog'}
                        </button> */}
                    </div>
                </div>

                <ErrorBoundary fallback={<div>Something went wrong. Please try again later.</div>}>
                    {isManagementMode ? (
                        <CatalogManagement
                            categories={categories}
                            items={items}
                            catalogId={catalogId}
                            onReorderSuccess={handleReorderSuccess}
                            onError={handleError}
                        />
                    ) : (
                        <>

                            {/* Categories overview */}
                            {categories.length > 0 && ( // Only appear if there is at least 1 category
                                <div className="bg-white rounded-2xl shadow-md p-4 sm:p-6 mb-6 max-w-full overflow-hidden">
                                    <h2 className="text-xl font-bold mb-4">Categories</h2>
                                    <div className="relative">
                                        {/* <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
                                        <button onClick={() => scroll(-100)} className="p-1 rounded-full bg-white shadow-md hover:bg-gray-100">
                                            <ChevronLeft size={10} />
                                        </button>
                                    </div>
                                    <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
                                        <button onClick={() => scroll(100)} className="p-1 rounded-full bg-white shadow-md hover:bg-gray-100">
                                            <ChevronRight size={10} />
                                        </button>
                                    </div> */}
                                        <div
                                            ref={categoriesRef}
                                            className="flex overflow-x-auto scrollbar-hide space-x-2 sm:space-x-4 pb-2 px-8 cursor-grab active:cursor-grabbing"
                                            onMouseDown={handleMouseDown}
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            onTouchEnd={handleMouseUp}
                                            style={{
                                                scrollbarWidth: 'none',
                                                msOverflowStyle: 'none',
                                                userSelect: 'none',
                                            }}
                                        >
                                            {categories.map((category) => (
                                                <button
                                                    key={category.id}
                                                    onClick={() => scrollToCategory(category.id)}
                                                    className="flex-shrink-0 bg-white rounded-md px-3 py-3 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium hover:bg-gray-200 transition-colors border-gray-300"
                                                >
                                                    {category.name} <span className='bg-blue-300 text-#191970 p-1 px-2 ml-2 rounded-sm font-bold aspect-square'>{category.itemCount || 0}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Items section */}
                            <div className='mb-16'>
                                <div className="flex justify-between items-center mb-4">
                                    <h4 className="text-lg font-bold">Items</h4>
                                    {/* <button
                                        className="flex items-center bg-white text-[#191970]"
                                        onClick={() => setFilter(filter === 'all' ? 'shown' : filter === 'shown' ? 'hidden' : 'all')}
                                    >
                                        <Filter className="w-4 h-4 mr-2" />
                                        <span className="hidden sm:inline">{filter === 'all' ? 'All' : filter === 'shown' ? 'Shown' : 'Hidden'}</span>
                                    </button> */}
                                </div>

                                {/* Categorized items */}
                                <div className="space-y-8">
                                    {categories.map((category) => (
                                        <div key={category.id} ref={el => categoryRefs.current[category.id] = el}>
                                            <div className="flex justify-between items-center bg-gray-200 p-2 rounded-t-xl">
                                                <h6 className="text-base lg:pl-5 font-semibold">{category.name}</h6>
                                                <div className="flex items-center ">
                                                    {/* <Toggle
                                                        isOn={category.isVisible}
                                                        onToggle={() => handleToggleCategoryVisibility(category.id)}
                                                    /> */}
                                                    <button
                                                        onClick={() => addQuickItem(category.id, category.name)}
                                                        className="bg-[#191970] text-white px-2 py-1 sm:px-3 sm:py-1 rounded-full text-xs sm:text-sm flex items-center"
                                                    >
                                                        <Plus className="w-3 h-3" /> <span className="hidden sm:inline">Quick Add</span>
                                                    </button>
                                                    <button
                                                        onClick={() => handleEditCategory(category)}
                                                        className="text-blue-500 hover:text-blue-700 bg-transparent px-3 ml-7"
                                                    >
                                                        <Edit size={17} />
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeleteCategoryClick(category)}
                                                        className="text-red-500 hover:text-red-900 bg-transparent px-3 ml-1"
                                                    >
                                                        <Trash size={17} />
                                                    </button>
                                                    <button onClick={() => toggleCategoryExpansion(category.id)} className='bg-white'>
                                                        {category.isExpanded ? <ChevronUp /> : <ChevronDown />}
                                                    </button>
                                                </div>
                                            </div>


                                            {category.isExpanded && (
                                                <div>
                                                    {category.items.length > 0 ? (
                                                        <ItemGrid
                                                            items={category.items.filter(item =>
                                                                filter === 'all' ||
                                                                (filter === 'shown' && item.isVisible) ||
                                                                (filter === 'hidden' && !item.isVisible)
                                                            )}
                                                            onToggleItemVisibility={handleToggleItemVisibility}
                                                            onOpenDetail={handleOpenItemDetail}
                                                        />
                                                    ) : (
                                                        <p className="text-gray-500 text-sm text-center pt-2">Let's fill this category! Use the quick add button (+) to add items. </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>


                                {/* Uncategorized items */}
                                {data.catalogItemsAndCategories.uncategorizedItems.length > 0 && (
                                    <div className="mt-8">
                                        {categories.length > 0 && (  // only display heading if there are other categories
                                            <h6 className="text-base font-semibold bg-gray-200 h-14 rounded-t-xl p-2 pt-4">Other Items</h6>
                                        )}
                                        <ItemGrid
                                            items={data.catalogItemsAndCategories.uncategorizedItems.filter(item =>
                                                filter === 'all' ||
                                                (filter === 'shown' && item.isVisible) ||
                                                (filter === 'hidden' && !item.isVisible)
                                            )}
                                            onToggleItemVisibility={handleToggleItemVisibility}
                                            onOpenDetail={handleOpenItemDetail}
                                        />

                                    </div>
                                )}

                                {data.catalogItemsAndCategories.uncategorizedItems.length == 0 && categories.length == 0 && (
                                    <div>
                                        <p className='text-gray-500 text-center'>Let's get started! Click the 'New Item' or 'New Category' buttons above to add your first products and start building your catalog.</p>
                                    </div>
                                )
                                }

                            </div>
                        </>
                    )}

                </ErrorBoundary>

                {/* Popups and modals */}
                <AddItemPopup
                    isOpen={isAddItemPopupOpen}
                    onClose={() => {
                        setIsAddItemPopupOpen(false);
                        setQuickAddCategory(null);
                    }}
                    onSubmit={handleAddItem}
                    categories={categories}
                    quickAddCategory={quickAddCategory}
                    catalogId={catalogId}
                />

                {isEditItemPopupOpen && itemToEdit && (
                    <EditItemPopup
                        isOpen={isEditItemPopupOpen}
                        onClose={() => {
                            // console.log("Closing edit item popup");
                            setIsEditItemPopupOpen(false);
                            setItemToEdit(null);
                        }}
                        onSubmit={handleEditItem}
                        item={itemToEdit}
                        categories={categories}
                        catalogId={catalogId}
                    />
                )}

                <AddCategoryPopup
                    isOpen={isAddCategoryPopupOpen}
                    onClose={() => setIsAddCategoryPopupOpen(false)}
                    onSubmit={handleAddCategory}
                    catalogId={catalogId}
                />

                <ItemDetailPopup
                    item={selectedItem}
                    isOpen={isItemDetailPopupOpen}
                    onClose={() => setIsItemDetailPopupOpen(false)}
                    onEdit={handleOpenEditItem}
                    onDelete={handleDeleteItemClick}
                    onToggleVisibility={handleToggleItemVisibility}
                />

                <EditCategoryPopup
                    isOpen={isEditCategoryPopupOpen}
                    onClose={() => setIsEditCategoryPopupOpen(false)}
                    onSubmit={handleEditCategorySubmit}
                    category={selectedCategory}
                />

                <DeleteConfirmationPopup
                    isOpen={isDeleteConfirmationOpen}
                    onClose={() => setIsDeleteConfirmationOpen(false)}
                    onConfirm={handleDeleteCategoryConfirm}
                    itemName="category"
                />

                <DeleteConfirmationPopup
                    isOpen={isDeleteItemConfirmationOpen}
                    onClose={() => {
                        setIsDeleteItemConfirmationOpen(false);
                        setItemToDelete(null);
                    }}
                    onConfirm={handleDeleteItemConfirm}
                    itemName="item"
                />

                <SuccessMessagePopup
                    isOpen={isSuccessMessageOpen}
                    onClose={() => setIsSuccessMessageOpen(false)}
                    title={successMessageContent.title}
                    message={successMessageContent.message}
                />

                <SuccessMessagePopup
                    isOpen={isSuccessMessageOpen}
                    onClose={() => {
                        setIsSuccessMessageOpen(false);
                        if (successMessageContent.title === "Partial Update") {
                            refetchCatalog();
                        }
                    }}
                    title={successMessageContent.title}
                    message={successMessageContent.message}
                />


                {/* <ReturnToOnboardingButton /> */}
            </div>
        </Layout >
    );
};

export default Catalog;