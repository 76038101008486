// styles/themes.ts

export interface Theme {
    colors: {
        background: string;
        text: string;
    };
    fonts: {
        main: string;
        heading: string;
        priColor: string;
        secColor: string;
    };
    layout: {
        header: string;
        footer: string;
        itemCard: string;
        itemPrice: string;
        categoriesOverview: string;
        categoriesOverviewActive: string;
        categoriesOverviewButtons: string;
        images: string;
        grid: string;
        list: string;
        detailpage: string;
    };
    detailPage: {
        main: string;
        descripButton: string;
        description: string;
        closeButton: string;
    };
    watermark: {
        color: string;
    };
}
export const Forest: Theme = {
    colors: {
        background: 'bg-gradient-to-br from-[#2e6f40] via-green-900 to-[#2e6f40]',
        text: 'text-green-200',
    },
    fonts: {
        main: 'font-sans',
        heading: 'font-serif text-green-100',
        priColor: 'text-green-50',
        secColor: 'text-green-50',
    },

    layout: {
        header: '',
        footer: 'bg-green-900 text-green-50',
        itemCard: 'bg-green-950 rounded-xl shadow-md m-1 p-2',
        itemPrice: 'text-sm font-bold text-green-50',
        categoriesOverview: 'bg-green-950 text-green-50',
        categoriesOverviewActive: 'bg-green-600',
        categoriesOverviewButtons: 'whitespace-nowrap shadow-[inset_0_2px_4px_0px_rgba(0,0,0,0.5)] px-3 py-1 text-sm rounded-md transition-colors duration-200',
        images: 'rounded-xl',
        grid: 'grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5',
        list: 'space-y-4',
        detailpage: "bg-gradient-to-br from-green-800 via-green-950 to-green-950 text-green-100"
    },
    detailPage: {
        main: "bg-gradient-to-br from-green-800 via-green-950 to-green-950 text-green-100",
        descripButton: "bg-green-100 text-green-900",
        description: "text-green-100 opacity-70",
        closeButton: "text-green-100",
    },
    watermark: {
        color: 'filter invert saturate-0'
    }
};

export const defaultTheme: Theme = {
    colors: {
        background: 'bg-white',
        text: 'text-gray-700',
    },
    fonts: {
        main: 'font-sans',
        heading: 'font-serif text-gray-900',
        priColor: 'text-gray-900',
        secColor: 'text-gray-900',  //item name and description with 70% opacity
    },
    layout: {
        header: 'p-4 bg-white border-b border-gray-200',
        footer: 'bg-gray-100 text-gray-800 text-xs py-4 text-center',
        itemCard: 'bg-white p-2 shadow-md hover:shadow-md transition-shadow duration-300 rounded-br-2xl overflow-hidden',
        itemPrice: 'flex justify-end font-bold text-sm text-gray-900',
        categoriesOverview: 'bg-gray-100 text-gray-900',
        categoriesOverviewActive: 'bg-gray-900 text-gray-100',
        categoriesOverviewButtons: 'px-3 py-2 text-sm transition-colors duration-200 bg-gray-200 hover:bg-gray-400 mx-1 rounded-full',
        images: 'rounded-md',
        grid: 'grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5',
        list: 'space-y-4 divide-y divide-gray-200',
        detailpage: "bg-white"
    },
    detailPage: {
        main: "bg-gray-100 text-gray-900",
        descripButton: "bg-gray-900 text-gray-100",
        description: "text-gray-600",
        closeButton: "text-gray-900",
    },
    watermark: {
        color: ''
    }
};

export const Classic: Theme = {
    colors: {
        background: 'bg-white',
        text: 'text-gray-700',
    },
    fonts: {
        main: 'font-sans',
        heading: 'font-serif text-gray-900',
        priColor: 'text-gray-900',
        secColor: 'text-gray-900',  //item name and description with 70% opacity
    },
    layout: {
        header: 'p-4 bg-white border-b border-gray-200',
        footer: 'bg-gray-100 text-gray-800 text-xs py-4 text-center',
        itemCard: 'bg-white p-2 shadow-md hover:shadow-md transition-shadow duration-300 rounded-br-2xl overflow-hidden',
        itemPrice: 'flex justify-end font-bold text-sm text-gray-900',
        categoriesOverview: 'bg-gray-100 text-gray-900',
        categoriesOverviewActive: 'bg-gray-900 text-gray-100',
        categoriesOverviewButtons: 'px-3 py-2 text-sm transition-colors duration-200 bg-gray-200 hover:bg-gray-400 mx-1 rounded-full',
        images: 'rounded-md',
        grid: 'grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5',
        list: 'space-y-4 divide-y divide-gray-200',
        detailpage: "bg-white"
    },
    detailPage: {
        main: "bg-gray-100 text-gray-900",
        descripButton: "bg-gray-900 text-gray-100",
        description: "text-gray-600",
        closeButton: "text-gray-900",
    },
    watermark: {
        color: ''
    }
};


export const Modern: Theme = {
    colors: {
        background: 'bg-gradient-to-br from-gray-700 to-gray-800',
        text: 'text-gray-200',
    },
    fonts: {
        main: 'font-nunito',
        heading: 'font-nunito text-white ',
        priColor: 'text-white',
        secColor: 'text-white', //item name and description with 70% opacity
    },
    layout: {
        header: 'shadow-md p-4 bg-gray-800',
        footer: 'bg-gray-800 text-gray-200',
        itemCard: 'bg-gray-800 shadow-xl rounded-xl p-2',
        itemPrice: 'text-white flex justify-end text-sm font-bold',
        categoriesOverview: 'bg-gray-800 text-white',
        categoriesOverviewActive: 'bg-white text-gray-800',
        categoriesOverviewButtons: 'bg-gray-800 whitespace-nowrap px-3 py-1 text-sm rounded-md transition-colors duration-200',
        images: 'rounded-md',
        grid: 'grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5',
        list: 'space-y-4',
        detailpage: "bg-gray-800 text-white"
    },
    detailPage: {
        main: "bg-gray-800 text-white",
        descripButton: "bg-gray-100 text-gray-800",
        description: "text-gray-400",
        closeButton: "text-gray-200",
    },
    watermark: {
        color: 'filter invert saturate-0'
    }
};

export const Lavender: Theme = {
    colors: {
        background: 'bg-gradient-to-br from-[#d3d3ff] to-[#D3E9FF]',
        text: 'text-[#09093390]', //header description
    },
    fonts: {
        main: 'font-nunito',
        heading: 'font-nunito text-[#090933]',
        priColor: 'text-[#090933]',
        secColor: 'text-[#090933]', //item name and description with 80% opacity
    },
    layout: {
        header: 'shadow-md p-4 text-red-500',
        footer: 'bg-[#d3E9FF] text-[#090933]',
        itemCard: 'bg-[#FFFFE3] font-[#09093370] shadow-inner rounded-xl p-2',
        itemPrice: 'text-[#090933] flex justify-end text-sm font-bold',
        categoriesOverview: 'bg-[#FFFFE3] text-[#090933]',
        categoriesOverviewActive: 'bg-[#09093370] text-[#FFFFE3]',
        categoriesOverviewButtons: 'text-[#090933] whitespace-nowrap px-3 py-1 text-sm rounded-md transition-colors duration-200',
        images: 'rounded-md',
        grid: 'grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5',
        list: 'space-y-4',
        detailpage: ""
    },
    detailPage: {
        main: "bg-gradient-to-br from-[#d3d3ff] to-[#D3E9FF] text-[#090933]",
        descripButton: "bg-[#090933] text-[#d3d3ff]",
        description: "text-[#09093390]",
        closeButton: "text-[#090933]",
    },
    watermark: {
        color: ''
    }
};

export const Taupe: Theme = {
    colors: {
        background: 'bg-gradient-to-tl from-[#54463a] to-[#826d5a]',
        text: 'text-[#F7E6CA] opacity-80',
    },
    fonts: {
        main: 'font-sans',
        heading: 'font-serif text-[#F7E6CA]',
        priColor: 'text-[#F7E6CA]',
        secColor: 'text-[#54463a]',
    },
    layout: {
        header: 'bg-gradient-to-b from-[#54463a] to-transparent',
        footer: 'bg-[#54463a] text-[#F7E6CA]',
        itemCard: 'shadow-inner bg-[#F7E6CA] p-2 rounded-xl',
        itemPrice: 'text-sm font-bold text-[#54463a]',
        categoriesOverview: 'bg-[#826d5a] text-[#F7E6CA]',
        categoriesOverviewActive: 'text-[#826d5a] bg-[#F7E6CA]',
        categoriesOverviewButtons: 'bg-transparent whitespace-nowrap px-3 py-1 text-sm rounded-md transition-colors duration-200',
        images: 'rounded-md',
        grid: 'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5',
        list: 'space-y-4',
        detailpage: "bg-white"
    },
    detailPage: {
        main: "bg-gradient-to-tl from-[#54463a] to-[#826d5a] text-[#F7E6CA]",
        descripButton: "bg-[#F7E6CA] text-[#54463a]",
        description: "text-[#F7E6CA] opacity-70",
        closeButton: "text-[#F7E6CA]",
    },
    watermark: {
        color: 'filter invert saturate-0'
    }
};





export const Blueberry: Theme = {
    colors: {
        background: 'bg-[#cce6fc]',
        text: 'text-blue-800',
    },
    fonts: {
        main: 'font-sans',
        heading: 'font-serif text-[#191970]',
        priColor: 'text-[#191970]',
        secColor: 'text-blue-800',  //item description
    },
    layout: {
        header: 'p-4 bg-[#cce6fc] border-b border-gray-200',
        footer: 'bg-[#cce6fc] text-[#191970] text-xs py-4 text-center',
        itemCard: 'bg-white p-2 hover:shadow-md transition-shadow duration-300 rounded-[1.8rem] overflow-hidden',
        itemPrice: 'flex justify-end pr-2 font-bold text-sm text-[#191970]',
        categoriesOverview: 'bg-[#cce6fc] text-[#191970]',
        categoriesOverviewActive: 'bg-[#fad78e]',
        categoriesOverviewButtons: 'px-3 py-2 text-sm transition-colors duration-200 bg-white hover:opacity-80 mx-1 rounded-full',
        images: 'rounded-[1.7rem]',
        grid: 'grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5',
        list: 'space-y-2 divide-y',
        detailpage: "bg-[#cce6fc]"
    },
    detailPage: {
        main: "bg-[#cce6fc] text-[#191970]",
        descripButton: "bg-[#191970] text-[#cce6fc]",
        description: "text-[#191970] opacity-70",
        closeButton: "text-[#191970]",
    },

    watermark: {
        color: ''
    }

};


// export const VibrantBoutique: Theme = {
//     colors: {
//         primary: 'bg-pink-500 text-white',
//         secondary: 'bg-yellow-400 text-pink-800',
//         background: 'bg-gradient-to-br from-pink-100 to-yellow-100',
//         text: 'text-pink-800',
//     },
//     fonts: {
//         main: 'font-sans',
//         heading: 'font-cursive',
//         priColor: 'text-pink-800',
//         secColor: 'text-yellow-400',
//     },
//     layout: {
//         header: 'p-4 text-center bg-pink-500',
//         footer: 'bg-pink-200 text-pink-800 py-6 px-4 text-center',
//         itemCard: 'bg-white rounded-lg shadow-md overflow-hidden border-2 border-pink-300',
//         itemPrice: 'text-sm font-bold text-pink-600',
//         categoriesOverviewButtons: 'px-4 py-2 text-sm transition-colors duration-200 bg-yellow-300 hover:bg-yellow-400 text-pink-800 mx-1 rounded-full',
//         images: 'w-full h-48 object-cover',
//         grid: 'grid grid-cols-2 gap-4',
//         list: 'space-y-4',
//         detailpage: "bg-white"
//     },
//     watermark: {
//         color: ''
//     }
// };

// export const EcoFriendly: Theme = {
//     colors: {
//         primary: 'bg-green-600 text-green-50',
//         secondary: 'bg-green-100 text-green-800',
//         background: 'bg-green-50',
//         text: 'text-green-900',
//     },
//     fonts: {
//         main: 'font-sans',
//         heading: 'font-serif',
//         priColor: 'text-green-800',
//         secColor: 'text-green-100',
//     },
//     layout: {
//         header: 'p-4 bg-green-600',
//         footer: 'bg-green-100 text-green-700 text-sm py-8 px-4',
//         itemCard: 'bg-white border-2 border-green-200 rounded-lg p-2 overflow-hidden',
//         itemPrice: 'text-sm font-semibold text-green-700',
//         categoriesOverviewButtons: 'px-4 py-2 text-sm transition-colors duration-200 bg-green-100 hover:bg-green-200 text-green-800 mx-1 border-b-2 border-green-400',
//         images: 'w-full h-44 object-cover rounded-t-lg',
//         grid: 'grid grid-cols-2 gap-3',
//         list: 'space-y-3 divide-y divide-green-200',
//         detailpage: "bg-white"
//     },
//     watermark: {
//         color: ''
//     }
// };

// export const TechStartup: Theme = {
//     colors: {
//         primary: 'bg-purple-600 text-white',
//         secondary: 'bg-teal-400 text-purple-900',
//         background: 'bg-gray-50',
//         text: 'text-gray-800',
//     },
//     fonts: {
//         main: 'font-sans',
//         heading: 'font-mono',
//         priColor: 'text-purple-900',
//         secColor: 'text-teal-400',
//     },
//     layout: {
//         header: 'p-4 flex justify-between items-center bg-purple-600',
//         footer: 'bg-purple-900 text-purple-100 text-xs py-6 px-4',
//         itemCard: 'bg-white rounded-lg shadow-md overflow-hidden border border-purple-200 hover:border-teal-400 transition-colors duration-300',
//         itemPrice: 'text-sm font-mono font-bold text-teal-500',
//         categoriesOverviewButtons: 'px-4 py-2 text-sm transition-colors duration-200 bg-purple-100 hover:bg-purple-200 text-purple-800 mx-1 border-l-4 border-purple-500',
//         images: 'w-full h-40 object-cover',
//         grid: 'grid grid-cols-2 gap-3',
//         list: 'space-y-3',
//         detailpage: "bg-white"
//     },
//     watermark: {
//         color: ''
//     }
// };

// export const VintageCharm: Theme = {
//     colors: {
//         primary: 'bg-amber-100 text-amber-900',
//         secondary: 'bg-amber-800 text-amber-100',
//         background: 'bg-amber-50',
//         text: 'text-amber-900',
//     },
//     fonts: {
//         main: 'font-serif',
//         heading: 'font-serif',
//         priColor: 'text-amber-900',
//         secColor: 'text-amber-100',
//     },
//     layout: {
//         header: 'p-6 text-center border-b-2 border-amber-200 bg-amber-100',
//         footer: 'bg-amber-200 text-amber-800 text-sm py-8 px-4 text-center',
//         itemCard: 'bg-white border-4 border-amber-300 rounded-lg p-2 overflow-hidden',
//         itemPrice: 'text-sm font-bold text-amber-800',
//         categoriesOverviewButtons: 'px-4 py-2 text-sm transition-colors duration-200 bg-amber-200 hover:bg-amber-300 text-amber-900 mx-1 rounded-t-lg border-b-4 border-amber-400',
//         images: 'w-full h-44 object-cover rounded-t-lg',
//         grid: 'grid grid-cols-2 gap-4',
//         list: 'space-y-4 divide-y divide-amber-200',
//         detailpage: "bg-white"
//     },
//     watermark: {
//         color: ''
//     }
// };