import React, { useState, useCallback, useEffect } from 'react';
import CatalogPreview from './CatalogPreview';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { debounce } from 'lodash';

const DEBOUNCE_TIME = 3500; // 3.5 seconds

const FullPagePreview = ({ isOpen, onClose, catalog, categoriesWithItems, uncategorizedItems, selectedTheme, viewMode, onThemeChange }) => {
    if (!isOpen) return null;

    const themes = ['Classic', 'Modern', 'Blueberry', 'Lavender', 'Forest', 'Taupe'];
    const [localTheme, setLocalTheme] = useState(selectedTheme);
    const [isChanging, setIsChanging] = useState(false);
    const currentThemeIndex = themes.indexOf(localTheme);

    const debouncedThemeChange = useCallback(
        debounce((newTheme) => {
            onThemeChange(newTheme);
            setIsChanging(false);
        }, DEBOUNCE_TIME),
        [onThemeChange]
    );

    useEffect(() => {
        setLocalTheme(selectedTheme);
    }, [selectedTheme]);

    const handleThemeChange = (newTheme) => {
        setLocalTheme(newTheme);
        setIsChanging(true);
        debouncedThemeChange(newTheme);
    };

    const handlePreviousTheme = () => {
        const newIndex = (currentThemeIndex - 1 + themes.length) % themes.length;
        handleThemeChange(themes[newIndex]);
    };

    const handleNextTheme = () => {
        const newIndex = (currentThemeIndex + 1) % themes.length;
        handleThemeChange(themes[newIndex]);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col">
            <div className="bg-white w-full py-2 px-4 flex justify-between items-center">
                <h2 className="text-lg font-semibold">Theme Preview</h2>
                <button
                    onClick={onClose}
                    className="text-gray-800 bg-gray-50 hover:text-gray-600"
                    aria-label="Close preview"
                >
                    <X size={24} />
                </button>
            </div>
            <div className="flex-1 overflow-y-auto bg-gray-100">
                <div className="max-w-sm mx-auto my-4">
                    <CatalogPreview
                        businessInfo={{
                            name: catalog.name,
                            description: catalog.description,
                            logo: catalog.logo,
                            phone: catalog.phone,
                            socialMedia: catalog.socialMedia,
                        }}
                        categories={categoriesWithItems}
                        uncategorizedItems={uncategorizedItems}
                        themeName={localTheme}
                        viewMode={viewMode}
                    />
                </div>
            </div>
            <div className="bg-white w-full py-2 px-4 flex justify-between items-center">
                <button
                    onClick={handlePreviousTheme}
                    className="flex items-center bg-gray-50 text-gray-800 hover:text-gray-600"
                    disabled={isChanging}
                >
                    <ChevronLeft size={15} />
                    <span className="ml-1">Previous</span>
                </button>
                <span className="text-lg font-medium">
                    {localTheme} <span className='text-sm'> {isChanging && "(Previewing)"}</span>
                </span>
                <button
                    onClick={handleNextTheme}
                    className="flex items-center bg-gray-50 text-gray-800 hover:text-gray-600"
                    disabled={isChanging}
                >
                    <span className="mr-1">Next</span>
                    <ChevronRight size={15} />
                </button>
            </div>
        </div>
    );
};

export default FullPagePreview;