import React, { useRef } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaPhone, FaEnvelope, FaImage, FaWhatsapp } from 'react-icons/fa';
import themes from './Themes';


const ItemPlaceholder = ({ name }) => (
    <div className="bg-gray-100 rounded-lg flex items-center justify-center aspect-square">
        <div className="text-center">
            <FaImage className="mx-auto text-gray-400 mb-2" size={24} />
            <p className="text-xs text-gray-600 font-medium">{name}</p>
        </div>
    </div>
);


const CatalogPreview = ({ businessInfo, categories, uncategorizedItems, themeName, viewMode, onViewModeChange }) => {
    const theme = themes[themeName] || themes.Classic; // Default to Classic if theme not found
    const headerRef = useRef(null);

    const allCategories = [...categories, { id: 'uncategorized', name: 'Others', order: Infinity, items: uncategorizedItems }];
    const sortedCategories_ = allCategories.sort((a, b) => a.order - b.order);

    // Filter out empty categories
    const sortedCategories = sortedCategories_.filter((category) => category.items.some((item) => item));

    const renderItem = (item) => (
        <div
            key={item.id}
            className={` ${theme.layout.itemCard} overflow-hidden ${viewMode === 'list' ? 'flex items-center' : ''}`}
        >
            {item.image && (
                <div className={viewMode === 'grid' ? 'aspect-square' : 'w-20 h-20 flex-shrink-0'}>

                    <img
                        src={item.image}
                        alt={item.name}
                        className={`${theme.layout.images} ${viewMode === 'grid' ? 'w-full h-full' : 'w-20 h-20'} object-cover`}
                    />

                </div>
            )}
            <div className={`${viewMode === 'list' ? 'flex-grow px-2' : 'pt-2'}`}>
                <h5 className={`${theme.fonts.secColor} text-sm font-semibold `}>{item.name}</h5>
                {item.description && <p className={`text-xs ${theme.fonts.secColor} opacity-65 my-1 line-clamp-2`}>{item.description}</p>}
                <p className={`${theme.layout.itemPrice} `}>
                    {item.priceVariants && item.priceVariants.length > 0
                        ? `${item.priceVariants[0].price.toLocaleString('en-NG', { style: 'currency', currency: 'NGN', minimumFractionDigits: 0 })}`
                        : 'Price not available'}
                </p>
            </div>
        </div>
    );

    return (
        <div className={`lg:w-[390px] lg:h-[640px] overflow-y-auto border-8 border-black rounded-3xl ${theme.colors.background} sm:w-full sm: h-full`}>
            <header ref={headerRef} className={`${theme.layout.header}`}>
                <div className="flex items-center ">
                    <div className="flex items-center flex-col mx-auto">
                        {businessInfo.logo && (
                            <img
                                src={businessInfo.logo}
                                alt="Logo"
                                className="w-20 h-20 rounded-full object-cover"
                            />
                        )}
                        {!businessInfo.logo && ( // Display name if no logo
                            <h1 className={`text-4xl mt-4 font-bold ${theme.fonts.heading}`}>{businessInfo.name}</h1>
                        )}

                        {businessInfo.logo && ( // Display smallname if there is logo
                            <h1 className={`text-2xl font-bold ${theme.fonts.heading}`}>{businessInfo.name}</h1>
                        )}
                        <hr />
                        <p className={`text-xs mt-1 mb-4 ${theme.colors.text}`}>{businessInfo.description}</p>
                    </div>
                </div>
            </header>

            <nav className={`sticky top-0 ${theme.layout.categoriesOverview} bg-opacity-70 backdrop-filter backdrop-blur-lg shadow-md p-2 z-10 overflow-x-auto`}>
                <div className="flex space-x-2 pr-4 min-w-max">
                    {categories.length > 0 && ( // Check if there are categories
                        sortedCategories.map((category) => (
                            <button
                                key={category.id}
                                className={`${theme.layout.categoriesOverviewButtons} hover:bg-opacity-80`}
                            >
                                {category.name}
                            </button>
                        ))
                    )}
                </div>
            </nav>

            <main className={`mt-4 min-h-[62%] px-4 ${theme.colors.text}`}>

                {sortedCategories.map((category) => (
                    <div key={category.id} className="mb-8">
                        {sortedCategories.length > 1 && sortedCategories[0] !== "Others" && ( // Check if there are categories

                            <h2 className={`text-xl font-bold mt-6 mb-4 ${theme.fonts.priColor}`}>{category.name}</h2>
                        )}
                        <div className={viewMode === 'grid' ? theme.layout.grid : theme.layout.list}>
                            {category.items.map(renderItem)}
                        </div>
                    </div>
                ))}
            </main>

            {(businessInfo.phone || businessInfo.socialMedia?.facebook || businessInfo.socialMedia?.instagram) && (
                <div className={`sticky bottom-0 ${theme.layout.footer} bg-opacity-90 backdrop-filter backdrop-blur-lg p-2 pt-4 h-14 border-t-gray-200 border-t-2`}>
                    <div className={`flex space-x-5 my-auto justify-center`}>
                        {businessInfo.phone && (
                            <>
                                <a href={`tel:${businessInfo.phone}`} className={`${theme.layout.footer} hover:opacity-70`}>
                                    <FaPhone size={20} />
                                </a>
                                <a href={`https://wa.me/${businessInfo.phone}`} className={`${theme.layout.footer} hover:opacity-70`}>
                                    <FaWhatsapp size={20} />
                                </a>
                            </>
                        )}
                        {businessInfo.socialMedia?.facebook && (
                            <a href={businessInfo.socialMedia.facebook} className={`${theme.layout.footer} hover:opacity-70`}>
                                <FaFacebook size={20} />
                            </a>
                        )}
                        {businessInfo.socialMedia?.instagram && (
                            <a href={businessInfo.socialMedia.instagram} className={`${theme.layout.footer} hover:opacity-70`}>
                                <FaInstagram size={20} />
                            </a>
                        )}
                    </div>
                </div>
            )}

            <footer className={`${theme.layout.footer}`}>
                <hr className='w-2/5 mx-auto' />
                <a href='https://www.pricilog.com'>
                    <div className="flex flex-row items-start justify-center p-2 mb-2">
                        <p className={`text-xs ${theme.layout.footer} `}>Powered by |</p>
                        <img src='pricilogtext.png' alt='Pricilog Wordmark' className={`h-4 ml-1 mt-0.5 ${theme.watermark.color}`} />
                    </div>
                </a>
            </footer>

            {/* <div className="flex justify-center mt-2 bg-white">
                <button
                    className={`px-2 py-1 text-xs ${theme.layout.categoriesOverviewButtons} ${viewMode === 'grid' ? 'opacity-100' : 'opacity-50'}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        onViewModeChange('grid');
                    }}
                >
                    Grid
                </button>
                <button
                    className={`px-2 py-1 text-xs ${theme.layout.categoriesOverviewButtons} ${viewMode === 'list' ? 'opacity-100' : 'opacity-50'}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        onViewModeChange('list');
                    }}
                >
                    List
                </button>
            </div> */}
        </div>
    );
};

export default CatalogPreview;
