import React, { useCallback, useMemo, useState, useEffect, Suspense, lazy } from 'react';
import { useQuery } from '@apollo/client';
import { CATALOG_PERFORMANCE, TOP_ITEMS_BY_CLICKS } from '../graphql/queries';
import { useAuth } from '../utils/authContext';
import Layout from '../components/Layout';
import LoadingSpinner from '../components/LoadingSpinner';
import CatalogSelector from '../components/CatalogSelector';
import PerformanceMetrics from '../components/PerformanceMetrics';
import TopItems from '../components/TopItems';
import CatalogSharing from '../components/CatalogSharing';
import useRefetchOnInterval from '../hooks/useRefetchOnInterval';
import OnboardingFlow from '../components/OnboardingFlow';
import { useMutation } from '@apollo/client';
import { UPDATE_ONBOARDING_STATUS } from '../graphql/mutations';
import OnboardingPopup from '../components/OnboardingPopup';
import { COMBINED_OVERVIEW_DATA } from '../graphql/queries';
import PreviewInOverview from '../components/PreviewInOverview';


// New component for network issue message
const NetworkIssueMessage = () => (
    <div className="text-center p-4 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded">
        <p>It's taking longer than usual to load the page. There might be a network issue.</p>
        <p>Please try refreshing the page if this persists.</p>
        <button
            onClick={() => window.location.reload()}
            className="mt-2 px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
        >
            Refresh Now
        </button>
    </div>
);



const Overview = () => {
    const { user, selectedCatalog, refetchUserData } = useAuth();
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [isLoadingTooLong, setIsLoadingTooLong] = useState(false);
    const [hasRefetchedUserData, setHasRefetchedUserData] = useState(false);


    const [updateOnboardingStatus] = useMutation(UPDATE_ONBOARDING_STATUS);

    // Effect to refetch user data once when the component mounts
    useEffect(() => {
        if (!hasRefetchedUserData) {
            refetchUserData().then(() => {
                setHasRefetchedUserData(true);
            });
        }
    }, [refetchUserData, hasRefetchedUserData]);

    const { data: overviewData, loading: overviewLoading, refetch: refetchOverview } = useQuery(COMBINED_OVERVIEW_DATA, {
        variables: { catalogId: selectedCatalog?.id, days: 7, limit: 5 },
        skip: !selectedCatalog?.id,
        fetchPolicy: 'cache-and-network',
    });


    useEffect(() => {
        if (selectedCatalog) {
            setShowOnboarding(selectedCatalog.onboardingStatus !== 'COMPLETED' && selectedCatalog.onboardingStatus !== 'SKIPPED');
        }
    }, [selectedCatalog]);

    useEffect(() => {
        let timer;
        if (overviewLoading) {
            timer = setTimeout(() => {
                setIsLoadingTooLong(true);
            }, 10000);
        } else {
            setIsLoadingTooLong(false);
        }

        return () => clearTimeout(timer);
    }, [overviewLoading]);


    const handleOnboardingComplete = useCallback(async () => {
        try {
            await updateOnboardingStatus({
                variables: {
                    input: {
                        catalogId: selectedCatalog.id,
                        status: 'COMPLETED',
                        step: 0
                    }
                }
            });
            setShowOnboarding(false);
            refetchUserData(); // Refetch user data to update selectedCatalog
        } catch (error) {
            console.error('Error updating onboarding status:', error);
        }
    }, [selectedCatalog, updateOnboardingStatus, refetchUserData]);

    const handleOnboardingSkip = useCallback(async () => {
        try {
            await updateOnboardingStatus({
                variables: {
                    input: {
                        catalogId: selectedCatalog.id,
                        status: 'SKIPPED',
                        step: 0
                    }
                }
            });
            setShowOnboarding(false);
            refetchUserData(); // Refetch user data to update selectedCatalog
        } catch (error) {
            console.error('Error updating onboarding status:', error);
        }
    }, [selectedCatalog, updateOnboardingStatus, refetchUserData]);


    const performanceData = useMemo(() => overviewData?.overviewData.performance || {}, [overviewData]);
    const topItems = useMemo(() => overviewData?.overviewData.topItems || [], [overviewData]);

    // console.log('topItems:', topItems);

    if (!selectedCatalog || overviewLoading || !hasRefetchedUserData) {
        return (
            <>
                <LoadingSpinner />
                {isLoadingTooLong && <NetworkIssueMessage />}
            </>
        );
    }

    return (
        <Layout title="Overview" description="Have an insight about your growth">
            <div className="p-1 sm:p-1 lg:p-8 bg-gray-100">


                <CatalogSelector />
                <h2 className='hidden lg:block font-bold text-xl mb-5'>Welcome, {user.name}</h2>
                <div className="flex flex-col lg:flex-row gap-6">
                    <div className='lg:hidden'>
                        <CatalogSharing />
                        {/* Add the PreviewInOverview here */}
                        <div className="mt-4">
                            <PreviewInOverview catalogName={selectedCatalog.catalogName} />
                        </div>
                    </div>



                    <PerformanceMetrics data={performanceData} />
                    <TopItems items={topItems} />
                    <div className='hidden lg:block w-1/3'>
                        <CatalogSharing />
                    </div>
                </div>
            </div>

            <OnboardingPopup
                isOpen={showOnboarding}
                onComplete={handleOnboardingComplete}
                onSkip={handleOnboardingSkip}
                initialStep={selectedCatalog?.onboardingStep || 0}
            />

        </Layout>
    );
};

export default React.memo(Overview);
